<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row v-if="!fetchingData">
      <v-col cols="12" md="8">
        <v-card tile>
          <v-card-text>
            <table class="default-table">
              <tbody>
                <tr>
                  <td style="width: 150px">Titel</td>
                  <td>
                    <Patcher
                      :itemValue="data.title"
                      itemName="title"
                      :route="patchRoute"
                      inputElement="input"
                      inputType="text"
                      selectOptions=""
                      itemValueKey=""
                      itemTextKey=""
                      :previewValue="data.title"
                      @emit="
                        data.title = $event.value;
                        pageTitle = $event.value;
                      "
                    ></Patcher>
                  </td>
                </tr>

                <tr>
                  <td style="width: 150px">Motive</td>
                  <td>
                    <Patcher
                      :itemValue="data.foreign_ids"
                      itemName="foreign_ids"
                      :route="patchRoute"
                      inputElement=""
                      inputType="multiplemedia"
                      :multiple="true"
                      selectOptions=""
                      itemValueKey=""
                      itemTextKey=""
                      :previewValue="data.foreign_ids"
                      @emit="data.foreign_ids = $event.value"
                    ></Patcher>
                  </td>
                </tr>
              </tbody>
            </table>
            <v-btn depressed color="primary" disabled>
              Vorschau
              <v-icon right dark> mdi-open-in-new </v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader.vue";
import Patcher from "@/components/ui/Patcher";
export default {
  name: "Memory",

  components: {
    PageHeader,
    Patcher,
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  data() {
    return {
      pageTitle: null,
      pageDescription:
        "Hier kannst du die Motive eines Memory-Spiels hochladen. Aus jedem Motiv wird auf den Landingpages ein Bildpaar erzeugt. 4 Motive generieren ein Memory-Spiel aus 8 Karten.",
      itemId: this.$route.params.id,
      fetchingData: false,
      data: null,
      patchRoute: `memories/${this.$route.params.id}`,
    };
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(`memories/${this.itemId}`);

      if (response.status === 200) {
        this.data = response.data.data;
        this.pageTitle = this.data.title;
      }

      this.fetchingData = false;
    },

    emittedAction(value) {
      if (value.action === "editItem") {
        this.$router.push({
          name: "tech.page-plugins.memories.:id",
          params: { id: value.itemId },
        });
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
